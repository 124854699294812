
:root {
  --color1: #fff;
  /* background */
  --color2: #F2F2F2;

  /* buttons, borders */
  --color3: #297DAC;

  /* font color */
  --color4: rgb(29, 29, 29);

  /* link color */
  --color5: #376DBD;

  /* side-bar bg color */
  --color6: #194BFB;

  --color7: #E2E8F0;

  --color8: #FAFAFA;

  width: 100%;
  height: 100%;
  background: #ffffff;
  position: fixed;
}

body {
  margin: 0;
  font-size: 16px;
  background-color: var(--color1);
  color: rgba(0,0,0);
  margin: 0 auto;
}

* {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea, input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="date"] {
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 1rem;
  border: 1px solid #E2E8F0;
  height: 3rem;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  border-radius: 0.75rem;
  color: #1A202C;
  width: 100%;
  line-height: 150%;
}

input[type="search"] {
  all: unset;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 0px 1rem;
  border: 1px solid #E2E8F0;
  height: 2.5rem;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  border-radius: 0.75rem;
  color: #1A202C;
  width: 100%;
}

.field_label{
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #718096;
  margin-bottom: 0.5rem;
}

/* input[disabled=""] {
   // opacity: 0.6;
} */

::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* textarea {
    line-height: 18px;
} */

textarea {
    min-height: 6rem;
    min-width: 300px;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  min-width: 6rem;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  cursor: pointer;
  line-height: 34px;
  padding: 0 36px;
  border-radius: 12px;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--color5);
}

.action__button {
  color: var(--color3);
  border: 1px solid var(--color3);
  border-radius: 100%;
  width: 26px;
  height: 26px;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

section {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  margin: 0 0 1.5em 0;
  padding: 20px 0;
}

.section__name {
  font-size: 1.125em;
  font-weight: normal;
  padding: 0 10px 0 30px;
  margin: 0;
  color: rgba(0,0,0,0.7);
}

.page__title {
    display: flex;
    align-items: baseline;
}

.create__btn {
    color: var(--color3);
    border-radius: 5px;
    border: 1px solid var(--color3);
    padding: 4px 22px;

    margin-left: auto;
    margin-right: 20px;
}

.standard__form {
    padding: 10px 0;
}

.d-flex{
  display: flex;
}

.flexBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flexBox > div {
    width: 400px;
}

.standard__form input, .sections__form input {
    width: 220px;
}

.standard__form label, .sections__form label {
    font-size: 0.750em;
    margin: 8px 0 2px;
    display: block;
    margin-left: 10px;
}

.standard__form button.accent, .sections__form button.accent {
    margin-top: 20px;
}

/* Buttons */

button.accent {
    background: var(--color6);
    border: 0 none;
    color: var(--color1);
    box-shadow: none;
    /* width: 220px; */
    line-height: 42px;
    font-weight: 600;
    width: auto;
    height: 40px;
    line-height: 34px;
    padding: 0 1rem!important;
    font-size: 14px;
    border-radius: 12px;
}

button.secondary {
  background: var(--color1);
  border: 1px solid #E2E8F0;
  color: var(--color6);
  box-shadow: none;
  /* width: 220px; */
  line-height: 42px;
  font-weight: 600;
  width: auto;
  height: 40px;
  line-height: 34px;
  padding: 0 1rem!important;
  font-size: 14px;
  border-radius: 12px;
}

button.tertiary {
  background: var(--color8);
  border: 1px solid #E2E8F0;
  color: var(--color6);
  box-shadow: none;
  /* width: 220px; */
  line-height: 42px;
  font-weight: 600;
  width: auto;
  height: 32px;
  line-height: 28px;
  padding: 0 1rem!important;
  font-size: 14px;
  border-radius: 1000px;
}

.remove__icon {
    position: relative;
    left: 20px;
    top: 8px;
}

.Dropdown-root {
    width: 220px;
}

.Dropdown-control {
    border: 1px solid var(--color3) !important;
    border-radius: 5px !important;
    font-size: 12px !important;
}

.Dropdown-placeholder {
    color: var(--color4) !important;
}

.Dropdown-option, .Dropdown-placeholder {
  text-transform: capitalize;
}

/* .select {
    width: 220px;
} */

.select > div > div, .select > div > div:hover {
    border: 1px solid var(--color7) !important;
    box-shadow: none !important;
}

.separator {
  border-top: 1px solid #AAAAAA;
  width: 230px;
  margin: 20px 0;
}

 /* Customize the label (the container) */
 .checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark, .checkmark:hover {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid var(--color3);
  border-radius: 5px;
  opacity: 1;
}

.checkbox input:checked ~ .checkmark {
  border-color: var(--color3);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid var(--color3);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hint {
    display: block;
    margin-top: 2px;
}

.mr-1{
  margin-right: 0.25rem !important;
}
.mr-2{
  margin-right: 0.5rem !important;
}
.mr-3{
  margin-right: 0.75rem !important;
}
.mr-4{
  margin-right: 1rem !important;
}

.mb-0{
  margin-bottom: 0 !important;
}
.mb-1{
  margin-bottom: 0.25rem !important;
}
.mb-2{
  margin-bottom: 0.5rem !important;
}
.mb-3{
  margin-bottom: 0.75rem !important;
}
.mb-4{
  margin-bottom: 1rem !important;
}
.mb-auto{
  margin: auto;
}
#w33{
  width: 33%;
}
.fw-600{
  font-weight: 600;
}

.pt15{
  padding-top: 1.5rem;
}
