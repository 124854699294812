.menu {
    width: 16em;
    height: 100%;
    position: fixed;
    left: -16em;
    top: 0;
    background-color: var(--color6);
    transition: 0.2s left ease;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05);
    z-index: 4;
    overflow-y: auto;
}

.menu.visible {
    left: 0;
}

.menu.visible hr{
    margin-inline: 10px;
}

.app__container {
    padding-left: 0;
    transition: 0.2s all ease;
}

.app__container.app__container--menu-visible {
    padding-left: 16em;
    width: calc(100% - 16rem);
}

.app__logo img {
    height: 80px;
    display: block;
    margin: 0.5rem auto;
}

.app__menu-links {
    padding: 10px;
    text-align: start;
    padding-top: 0px;
}

.app__menu-links a {
    display: block;
    padding: 10px 0 10px 0px;
    border-bottom: 1px solid var(--color6);
    color: var(--color1);
    padding-left: 3rem;
    margin-top: 0.5em;
    display: flex;
}

.app__menu-links a > svg {
    margin-right: 12px;
}

.app__menu-links a:first-child {
    margin-top: 3em !important;
}

.app__menu-links a:last-child {
    border-bottom: 0 none;
}

.app__menu-links > a.active {
    background: white;
    color: var(--color6);
    border-radius: 12px;
    font-weight: 600;
}

.app__menu-links > a.active > svg > path {
    fill: var(--color6);
}
