.business_profile_section {
    width: 20rem;
    /* position: fixed; */
    height: 100%;
    border-right: 1px solid #E2E8F0;
    padding-top: 0.5rem;
}

.business_profile_section h4 {
    font-size: 16px;
    padding: 2rem 2.5rem;
    color: #718096;
    margin: auto;
}

.section_tabs {
    height: 5em;
    cursor: pointer;
    padding-left: 0.5rem;
}

.section_tabs.active {
    background: #FAFAFA;
    border-left: 3px solid #194BFB;
}

.section_tabs.active h5 {
    color: #194BFB;
}

.section_tabs h5{
    font-size: 14px;
    padding: 1rem 2rem 0rem 2rem;
    margin: auto;
}

.section_tabs p {
    padding: 0rem 2rem 0rem 2rem;
    font-size: 12px;
    color: #718096;
    line-height: 18px;
}

.general_detail_section {
    /* margin-left: 20rem; */
    /* position: fixed; */
    padding: 1rem 2rem;
    width: calc(100% - 22rem);
    overflow-y: auto;
}

.show {
    opacity: 1;
    display: block;
}

.hide {
    opacity: 0;
    transition: opacity 2s ease-out;
}

.integration_message--success {
    font-size: 18px;
    padding: 8px 12px;
    font-weight: 600;
    background: #25b325;
    color: #fff;
    border: 0 none;
    border-radius: 12px;
}

.integration_message--error {
    font-size: 18px;
    padding: 8px 12px;
    font-weight: 600;
    background: #dd2929;
    color: #fff;
    border: 0 none;
    border-radius: 12px;
}

.section_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.section_title button {
    width: auto;
    height: 40px;
    line-height: 34px;
    padding: 0 36px!important;
    font-size: 14px;
}

.form_details {
    margin-top: 1rem;
}

.form_details .flexBox > *{
    padding-top: 1.5rem;
    padding-bottom: 1rem;
}

.form_details .flexBox > #w50{
    width: 48%;
}

.form_details .flexBox > #w100{
    width: 100%;
}

.form_details .flexBox > #w31{
    width: 31%;
}
.form_title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #1A202C;
}

.clipboard_icon{
    height: 20px;
    margin-left: 0.5rem;
    cursor: pointer;
}

.fb__button {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 150px;
    min-height: 150px;
}

.fb__button:hover {
    box-shadow: 1px 1px 10px #ddd;
}

.fb__button--icon {
    font-size: 34px;
    margin: 10px auto 0;
    color: #316FF6;
}

.fb__button--text {
    margin: 0 auto 10px;
    line-height: 21px;
}

.disabled {
    background: #eee;
}

.disabled:hover {
    cursor:default;
    box-shadow: none;
}
