.app__header {
    width: 100%;
    height: 6em;
    background-color: var(--color1);
    display: flex;
    align-items: center;
    /* margin-bottom: 1em; */
    /* border: 1px solid #E2E8F0; */
}

.app__header p{
    margin-left: 2.5rem;
    font-size: 2rem;
    font-weight: 600;
}

.app__header a img {
    height: 4em;
}

.app__header .sign__out {
    font-size: 1.125;
    line-height: 1.5em;
    color: var(--color3);
    border: 1px solid var(--color3);
    border-radius: 5px;
    padding: 4px 22px;
    margin-left: auto;
    margin-right: 1.25em;
    font-size:0.875;
    cursor: pointer;
}

.app__header > a {
    margin: auto 10px;
    font-size: 0.875em;
}

.app__header > a {
    margin-left: 1.125em;
}
.app__menu-icon {
    margin: 0 10px 0 24px;
    padding: 5px 0;
}

.app__header-selected-company {
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.client__select {
    color: var(--color3);
    margin-bottom: 10px;
    display: block;
    position: fixed;
    border-radius: 0px;
}

.client__select > span {
    transition: 0.2s ease all;
    transform: rotate(90deg);
    position: relative;
    display: inline-block;
    margin-left: 10px;
}

.client__select > span.expanded {
    transform: rotate(-90deg);
}

.client__select.close {
    color: #fff;
    transition: .25s;
}

.client__switch {
    padding: 30px 10px 10px 30px;
    margin-bottom: 10px;
    position: absolute;
    background-color: #fff;
    top: 0px;
    z-index: 4;
}

.client__switch.expanded {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5) !important;
    transition: .25s;
}

.client__select-icon {
    border-radius: 25px;
    font-size: 15px;
    font-weight: 600;
    position: inherit;
    padding: 0px;
    margin-top: 2px;
    min-width: auto;
    width: 28px;
    height: 28px;
    line-height: 26px;
}

.client__select-icon.close {
    background-color: #333;
    border: 1px solid #333;
}

.client__select_close:hover {
    cursor: pointer;
}

.client__select-icon.open {
    color: #fff;
    background-color: var(--color6);
    border: 1px solid var(--color6);
    transition: .25s;
}

.client__select_close:hover {
    cursor: pointer;
}

.client__list {
    display: none;
    width: 250px;
    height: 400px;
    padding: 5px 0px;
    box-shadow: 0 0 3px #808080;
    background-color: #fff;
    overflow-y: auto;
}

.client__list.expanded {
    display: block;
    margin-top: 35px;
    border-top: 3px solid var(--color3);
    transition: .25s;
}

.client__list ul li {
    margin: 10px 0;
    border-bottom: 1px solid var(--color2);
    padding: 10px 0px 0px 15px;
    color: var(--color3);
    cursor: pointer;
}

.client__list input {
    width: 100%;
    border-radius: 2px;
    border-color: var(--color2);
}
