.dashboard {
  padding: 2rem 2.5rem 1rem;
}

.dashboard p {
  font-size: 16px;
}

.dashboard_callout {
  padding: 1px 16px;
  border-left: 3px solid #194BFB;
  margin-bottom: 16px;
}

.dashboard_callout p {
  margin: 8px 0;
}

.dashboard_button {
  background-color: var(--color6);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

section button {
  margin-bottom: 5%;
}
