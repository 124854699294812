.search-field {
  display: flex;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;
  padding: 0rem 1rem;
  border: 1px solid #E2E8F0;
  border-radius: 0.75rem;
}

.search-field input {
  border: 0px;
}
