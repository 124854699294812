/* >>> GENERAL STYLE <<< */
section {
  padding: 0 2%;
}
/* >>> END <<< */
/* >>> HEADER STYLES <<< */
#header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

#header-section span {
  display: flex;
}

#header-section span button {
  display: inline;
  padding: 13px 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-family: 'Rubik', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #194BFB;
}
#header-section span button#filters {
  margin-right: 15px;
}

button#filters:hover {
  background: #194BFB;
  color: #fff;
  transition: .25s;
}

#header-section span button#add-new {
  border: 1px solid #194BFB;
  background-color: #194BFB;
  color: #fff;
}

#header-section span button#add-new:hover {
  border: 1px solid #133dd2;
  background-color: #133dd2;
  color: #fff;
  transition: .25s;
}
/* >>> END <<< */
/* >>> TABLE STYLE <<< */
/* === GENERAL STYLES === */
table {
  width: 100%;
}

thead tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: #718096;
  font-weight: 600;
  padding: 20px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

tbody tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #eee;
}

tbody td {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
/* === END === */
/* === INFO TABLE STYLES === */
/* --- File Name Col --- */
#file-name-col {
  font-weight: 600;
}

/* --- END --- */
/* --- Uploaded By Col --- */
#uploaded-col {
  font-size: 16px;
}
/* --- END --- */
/* --- Upload Date Col --- */
#date-col {
  font-family: 'Rubik', 'Open Sans', sans-serif;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

#upload-date {
  color: #1A202C;
  font-size: 16px;
}

#upload-time {
  color: #718096;
  font-size: 14px;
}
/* --- END --- */
/* --- Actions Col --- */
.action-btn {
display: inline;
padding: 8px 20px;
border: 1px solid #eee;
border-radius: 8px;
margin: 0 3px;
font-family: 'Rubik', 'Open Sans', sans-serif;
font-weight: 700;
font-size: 14px;
letter-spacing: 0.2px;
color: #194BFB;
}

.action-btn:hover {
  border: 1px solid #194BFB;
  background-color: #194BFB;
  color: #fff;
  cursor: pointer;
}
/* --- END --- */
/* === END === */
/* >>> END <<< */
/* >>> FILE UPLOADER <<< */
#file-upload {
  z-index: 4;
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.25);
}
/* >>> END <<< */
