
:root {
  --color1: #fff;
  /* background */
  --color2: #F2F2F2;

  /* buttons, borders */
  --color3: #297DAC;

  /* font color */
  --color4: rgb(29, 29, 29);

  /* link color */
  --color5: #376DBD;

  /* side-bar bg color */
  --color6: #194BFB;

  --color7: #E2E8F0;

  --color8: #FAFAFA;

  width: 100%;
  height: 100%;
  background: #ffffff;
  position: fixed;
}

body {
  margin: 0;
  font-size: 16px;
  background-color: #fff;
  background-color: var(--color1);
  color: rgba(0,0,0);
  margin: 0 auto;
}

* {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea, input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="date"] {
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 1rem;
  border: 1px solid #E2E8F0;
  height: 3rem;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  border-radius: 0.75rem;
  color: #1A202C;
  width: 100%;
  line-height: 150%;
}

input[type="search"] {
  all: unset;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 0px 1rem;
  border: 1px solid #E2E8F0;
  height: 2.5rem;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  border-radius: 0.75rem;
  color: #1A202C;
  width: 100%;
}

.field_label{
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #718096;
  margin-bottom: 0.5rem;
}

/* input[disabled=""] {
   // opacity: 0.6;
} */

::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* textarea {
    line-height: 18px;
} */

textarea {
    min-height: 6rem;
    min-width: 300px;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  min-width: 6rem;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  cursor: pointer;
  line-height: 34px;
  padding: 0 36px;
  border-radius: 12px;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #376DBD;
  color: var(--color5);
}

.action__button {
  color: #297DAC;
  color: var(--color3);
  border: 1px solid #297DAC;
  border: 1px solid var(--color3);
  border-radius: 100%;
  width: 26px;
  height: 26px;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

section {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  margin: 0 0 1.5em 0;
  padding: 20px 0;
}

.section__name {
  font-size: 1.125em;
  font-weight: normal;
  padding: 0 10px 0 30px;
  margin: 0;
  color: rgba(0,0,0,0.7);
}

.page__title {
    display: flex;
    align-items: baseline;
}

.create__btn {
    color: #297DAC;
    color: var(--color3);
    border-radius: 5px;
    border: 1px solid #297DAC;
    border: 1px solid var(--color3);
    padding: 4px 22px;

    margin-left: auto;
    margin-right: 20px;
}

.standard__form {
    padding: 10px 0;
}

.d-flex{
  display: flex;
}

.flexBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flexBox > div {
    width: 400px;
}

.standard__form input, .sections__form input {
    width: 220px;
}

.standard__form label, .sections__form label {
    font-size: 0.750em;
    margin: 8px 0 2px;
    display: block;
    margin-left: 10px;
}

.standard__form button.accent, .sections__form button.accent {
    margin-top: 20px;
}

/* Buttons */

button.accent {
    background: #194BFB;
    background: var(--color6);
    border: 0 none;
    color: #fff;
    color: var(--color1);
    box-shadow: none;
    /* width: 220px; */
    line-height: 42px;
    font-weight: 600;
    width: auto;
    height: 40px;
    line-height: 34px;
    padding: 0 1rem!important;
    font-size: 14px;
    border-radius: 12px;
}

button.secondary {
  background: #fff;
  background: var(--color1);
  border: 1px solid #E2E8F0;
  color: #194BFB;
  color: var(--color6);
  box-shadow: none;
  /* width: 220px; */
  line-height: 42px;
  font-weight: 600;
  width: auto;
  height: 40px;
  line-height: 34px;
  padding: 0 1rem!important;
  font-size: 14px;
  border-radius: 12px;
}

button.tertiary {
  background: #FAFAFA;
  background: var(--color8);
  border: 1px solid #E2E8F0;
  color: #194BFB;
  color: var(--color6);
  box-shadow: none;
  /* width: 220px; */
  line-height: 42px;
  font-weight: 600;
  width: auto;
  height: 32px;
  line-height: 28px;
  padding: 0 1rem!important;
  font-size: 14px;
  border-radius: 1000px;
}

.remove__icon {
    position: relative;
    left: 20px;
    top: 8px;
}

.Dropdown-root {
    width: 220px;
}

.Dropdown-control {
    border: 1px solid #297DAC !important;
    border: 1px solid var(--color3) !important;
    border-radius: 5px !important;
    font-size: 12px !important;
}

.Dropdown-placeholder {
    color: rgb(29, 29, 29) !important;
    color: var(--color4) !important;
}

.Dropdown-option, .Dropdown-placeholder {
  text-transform: capitalize;
}

/* .select {
    width: 220px;
} */

.select > div > div, .select > div > div:hover {
    border: 1px solid #E2E8F0 !important;
    border: 1px solid var(--color7) !important;
    box-shadow: none !important;
}

.separator {
  border-top: 1px solid #AAAAAA;
  width: 230px;
  margin: 20px 0;
}

 /* Customize the label (the container) */
 .checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark, .checkmark:hover {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #297DAC;
  border: 1px solid var(--color3);
  border-radius: 5px;
  opacity: 1;
}

.checkbox input:checked ~ .checkmark {
  border-color: #297DAC;
  border-color: var(--color3);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #297DAC;
  border: solid var(--color3);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hint {
    display: block;
    margin-top: 2px;
}

.mr-1{
  margin-right: 0.25rem !important;
}
.mr-2{
  margin-right: 0.5rem !important;
}
.mr-3{
  margin-right: 0.75rem !important;
}
.mr-4{
  margin-right: 1rem !important;
}

.mb-0{
  margin-bottom: 0 !important;
}
.mb-1{
  margin-bottom: 0.25rem !important;
}
.mb-2{
  margin-bottom: 0.5rem !important;
}
.mb-3{
  margin-bottom: 0.75rem !important;
}
.mb-4{
  margin-bottom: 1rem !important;
}
.mb-auto{
  margin: auto;
}
#w33{
  width: 33%;
}
.fw-600{
  font-weight: 600;
}

.pt15{
  padding-top: 1.5rem;
}

.app__header {
    width: 100%;
    height: 6em;
    background-color: var(--color1);
    display: flex;
    align-items: center;
    /* margin-bottom: 1em; */
    /* border: 1px solid #E2E8F0; */
}

.app__header p{
    margin-left: 2.5rem;
    font-size: 2rem;
    font-weight: 600;
}

.app__header a img {
    height: 4em;
}

.app__header .sign__out {
    font-size: 1.125;
    line-height: 1.5em;
    color: var(--color3);
    border: 1px solid var(--color3);
    border-radius: 5px;
    padding: 4px 22px;
    margin-left: auto;
    margin-right: 1.25em;
    font-size:0.875;
    cursor: pointer;
}

.app__header > a {
    margin: auto 10px;
    font-size: 0.875em;
}

.app__header > a {
    margin-left: 1.125em;
}
.app__menu-icon {
    margin: 0 10px 0 24px;
    padding: 5px 0;
}

.app__header-selected-company {
    margin: 0 auto;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.client__select {
    color: var(--color3);
    margin-bottom: 10px;
    display: block;
    position: fixed;
    border-radius: 0px;
}

.client__select > span {
    transition: 0.2s ease all;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    position: relative;
    display: inline-block;
    margin-left: 10px;
}

.client__select > span.expanded {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.client__select.close {
    color: #fff;
    transition: .25s;
}

.client__switch {
    padding: 30px 10px 10px 30px;
    margin-bottom: 10px;
    position: absolute;
    background-color: #fff;
    top: 0px;
    z-index: 4;
}

.client__switch.expanded {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5) !important;
    transition: .25s;
}

.client__select-icon {
    border-radius: 25px;
    font-size: 15px;
    font-weight: 600;
    position: inherit;
    padding: 0px;
    margin-top: 2px;
    min-width: auto;
    width: 28px;
    height: 28px;
    line-height: 26px;
}

.client__select-icon.close {
    background-color: #333;
    border: 1px solid #333;
}

.client__select_close:hover {
    cursor: pointer;
}

.client__select-icon.open {
    color: #fff;
    background-color: var(--color6);
    border: 1px solid var(--color6);
    transition: .25s;
}

.client__select_close:hover {
    cursor: pointer;
}

.client__list {
    display: none;
    width: 250px;
    height: 400px;
    padding: 5px 0px;
    box-shadow: 0 0 3px #808080;
    background-color: #fff;
    overflow-y: auto;
}

.client__list.expanded {
    display: block;
    margin-top: 35px;
    border-top: 3px solid var(--color3);
    transition: .25s;
}

.client__list ul li {
    margin: 10px 0;
    border-bottom: 1px solid var(--color2);
    padding: 10px 0px 0px 15px;
    color: var(--color3);
    cursor: pointer;
}

.client__list input {
    width: 100%;
    border-radius: 2px;
    border-color: var(--color2);
}

.business_profile_section {
    width: 20rem;
    /* position: fixed; */
    height: 100%;
    border-right: 1px solid #E2E8F0;
    padding-top: 0.5rem;
}

.business_profile_section h4 {
    font-size: 16px;
    padding: 2rem 2.5rem;
    color: #718096;
    margin: auto;
}

.section_tabs {
    height: 5em;
    cursor: pointer;
    padding-left: 0.5rem;
}

.section_tabs.active {
    background: #FAFAFA;
    border-left: 3px solid #194BFB;
}

.section_tabs.active h5 {
    color: #194BFB;
}

.section_tabs h5{
    font-size: 14px;
    padding: 1rem 2rem 0rem 2rem;
    margin: auto;
}

.section_tabs p {
    padding: 0rem 2rem 0rem 2rem;
    font-size: 12px;
    color: #718096;
    line-height: 18px;
}

.general_detail_section {
    /* margin-left: 20rem; */
    /* position: fixed; */
    padding: 1rem 2rem;
    width: calc(100% - 22rem);
    overflow-y: auto;
}

.show {
    opacity: 1;
    display: block;
}

.hide {
    opacity: 0;
    transition: opacity 2s ease-out;
}

.integration_message--success {
    font-size: 18px;
    padding: 8px 12px;
    font-weight: 600;
    background: #25b325;
    color: #fff;
    border: 0 none;
    border-radius: 12px;
}

.integration_message--error {
    font-size: 18px;
    padding: 8px 12px;
    font-weight: 600;
    background: #dd2929;
    color: #fff;
    border: 0 none;
    border-radius: 12px;
}

.section_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.section_title button {
    width: auto;
    height: 40px;
    line-height: 34px;
    padding: 0 36px!important;
    font-size: 14px;
}

.form_details {
    margin-top: 1rem;
}

.form_details .flexBox > *{
    padding-top: 1.5rem;
    padding-bottom: 1rem;
}

.form_details .flexBox > #w50{
    width: 48%;
}

.form_details .flexBox > #w100{
    width: 100%;
}

.form_details .flexBox > #w31{
    width: 31%;
}
.form_title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #1A202C;
}

.clipboard_icon{
    height: 20px;
    margin-left: 0.5rem;
    cursor: pointer;
}

.fb__button {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 150px;
    min-height: 150px;
}

.fb__button:hover {
    box-shadow: 1px 1px 10px #ddd;
}

.fb__button--icon {
    font-size: 34px;
    margin: 10px auto 0;
    color: #316FF6;
}

.fb__button--text {
    margin: 0 auto 10px;
    line-height: 21px;
}

.disabled {
    background: #eee;
}

.disabled:hover {
    cursor:default;
    box-shadow: none;
}

.search-field {
  display: flex;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;
  padding: 0rem 1rem;
  border: 1px solid #E2E8F0;
  border-radius: 0.75rem;
}

.search-field input {
  border: 0px;
}

.spinner_container {
  text-align: center;
  padding: 5rem;
}

#file-upload-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 30px 45px 45px;
  border-radius: 15px;
}

#close-icon {
  width: 100%;
  text-align: right;
}

#file-upload-popup > h2 {
  margin: 0 !important;
}

#file-upload-popup > p {
  margin-bottom: 10%;
}

#file-upload-popup > button#close-icon {
  padding: 0px !important;
  margin-bottom: 5px !important;
}

.form-input input[type="text"] {
  margin: 5px 0 10px;
}

button.btn--submit {
  width: 100%;
  background-color: #194BFB;
  color: #fff;
  font-family: 'Rubik', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 34px;
  padding: 0 1rem;
  border: 1px solid #194BFB;
  margin-top: 10px;
}

.success, .error {
  color: #fff;
  padding: .25em 1em;
  font-family: 'Rubik', 'Open Sans', sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 34px;
}

.error {
  background-color: red;
}

.success {
  background-color: green;
}

/* >>> GENERAL STYLE <<< */
section {
  padding: 0 2%;
}
/* >>> END <<< */
/* >>> HEADER STYLES <<< */
#header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

#header-section span {
  display: flex;
}

#header-section span button {
  display: inline;
  padding: 13px 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-family: 'Rubik', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #194BFB;
}
#header-section span button#filters {
  margin-right: 15px;
}

button#filters:hover {
  background: #194BFB;
  color: #fff;
  transition: .25s;
}

#header-section span button#add-new {
  border: 1px solid #194BFB;
  background-color: #194BFB;
  color: #fff;
}

#header-section span button#add-new:hover {
  border: 1px solid #133dd2;
  background-color: #133dd2;
  color: #fff;
  transition: .25s;
}
/* >>> END <<< */
/* >>> TABLE STYLE <<< */
/* === GENERAL STYLES === */
table {
  width: 100%;
}

thead tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: #718096;
  font-weight: 600;
  padding: 20px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

tbody tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #eee;
}

tbody td {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
/* === END === */
/* === INFO TABLE STYLES === */
/* --- File Name Col --- */
#file-name-col {
  font-weight: 600;
}

/* --- END --- */
/* --- Uploaded By Col --- */
#uploaded-col {
  font-size: 16px;
}
/* --- END --- */
/* --- Upload Date Col --- */
#date-col {
  font-family: 'Rubik', 'Open Sans', sans-serif;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

#upload-date {
  color: #1A202C;
  font-size: 16px;
}

#upload-time {
  color: #718096;
  font-size: 14px;
}
/* --- END --- */
/* --- Actions Col --- */
.action-btn {
display: inline;
padding: 8px 20px;
border: 1px solid #eee;
border-radius: 8px;
margin: 0 3px;
font-family: 'Rubik', 'Open Sans', sans-serif;
font-weight: 700;
font-size: 14px;
letter-spacing: 0.2px;
color: #194BFB;
}

.action-btn:hover {
  border: 1px solid #194BFB;
  background-color: #194BFB;
  color: #fff;
  cursor: pointer;
}
/* --- END --- */
/* === END === */
/* >>> END <<< */
/* >>> FILE UPLOADER <<< */
#file-upload {
  z-index: 4;
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.25);
}
/* >>> END <<< */

.report-details {
  padding: 2rem 2.5rem 1rem;
}

.menu {
    width: 16em;
    height: 100%;
    position: fixed;
    left: -16em;
    top: 0;
    background-color: var(--color6);
    transition: 0.2s left ease;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05);
    z-index: 4;
    overflow-y: auto;
}

.menu.visible {
    left: 0;
}

.menu.visible hr{
    margin-inline: 10px;
}

.app__container {
    padding-left: 0;
    transition: 0.2s all ease;
}

.app__container.app__container--menu-visible {
    padding-left: 16em;
    width: calc(100% - 16rem);
}

.app__logo img {
    height: 80px;
    display: block;
    margin: 0.5rem auto;
}

.app__menu-links {
    padding: 10px;
    text-align: start;
    padding-top: 0px;
}

.app__menu-links a {
    display: block;
    padding: 10px 0 10px 0px;
    border-bottom: 1px solid var(--color6);
    color: var(--color1);
    padding-left: 3rem;
    margin-top: 0.5em;
    display: flex;
}

.app__menu-links a > svg {
    margin-right: 12px;
}

.app__menu-links a:first-child {
    margin-top: 3em !important;
}

.app__menu-links a:last-child {
    border-bottom: 0 none;
}

.app__menu-links > a.active {
    background: white;
    color: var(--color6);
    border-radius: 12px;
    font-weight: 600;
}

.app__menu-links > a.active > svg > path {
    fill: var(--color6);
}

.dashboard {
  padding: 2rem 2.5rem 1rem;
}

.dashboard p {
  font-size: 16px;
}

.dashboard_callout {
  padding: 1px 16px;
  border-left: 3px solid #194BFB;
  margin-bottom: 16px;
}

.dashboard_callout p {
  margin: 8px 0;
}

.dashboard_button {
  background-color: var(--color6);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

section button {
  margin-bottom: 5%;
}

#customize-dialog-box > div > div {
    max-width: 750px;
    padding: 5px;
}

.customized-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 455px){
    .customized-dialog-header > div  {
        display: flex;
        flex-direction: column;
    }
}

.customized-dialog-header > div > button {
    border: 2px solid red;
    margin: 5px;
    height: 40px;
    color: red;
    font-size: 14px;
    font-weight: 600;
    line-height: 34px;
}

.customized-dialog-header > div > button#test-btn {
    background: var(--color1);
    border: 1px solid #E2E8F0 !important;
    color: var(--color6);
    box-shadow: none;
    line-height: 42px;
    font-weight: 600;
    width: auto;
    height: 40px;
    line-height: 34px;
    padding: 0 1rem!important;
    font-size: 14px;
    border-radius: 12px;
}

.delete-button {
    border: 2px solid red;
    margin: 5px;
    height: 40px;
    color: red;
    font-size: 14px;
    font-weight: 600;
    line-height: 34px;
}

.customized-dialog-header > button:hover {
    border: 2px solid red;
    color: #fff;
    background-color: red;
}

.customized-dialog-box {
    padding: 16px;
    border-radius: 4px;
}

.customized-dialog-box div[role="dialog"] {
    height: 500px;
}

.customized-dialog-box__code-section {
    overflow-x: scroll;
    overflow-wrap: break-word;
    padding: 8px;
    border: 1px solid #E2E8F0;
    border-radius: 4px;
    margin: 12px;
    box-shadow: none;
}

.customized-dialog-box__code-section::-webkit-scrollbar-track,
.customized-dialog-box__code-section::-webkit-scrollbar {
    background: #fff;
}

.customized-dialog-box__button {
    text-align: center;
    padding: 16px 0 0;
    margin-bottom: 0;
}

.customized-dialog-box button {
    width: 150px;
    background: var(--color6);
    border: 0 none;
    color: var(--color1);
    box-shadow: none;
    line-height: 42px;
    font-weight: 600;
    height: 40px;
    line-height: 34px;
    padding: 0 1rem!important;
    margin-bottom: 16px;
    font-size: 14px;
    border-radius: 12px;
}

.trigger_section_layout {
    padding: 2rem 2.5rem 1rem;
    height: calc(100vh - 9rem);
    overflow-y: auto;
}
.trigger_section {
    /* height: 100%; */
    justify-content: space-between;
    align-items: center;
}

.trigger_section input{
    width: 16rem;
}

.trigger_card {
    padding: 2rem 2rem 1.5rem;
    border: 1px solid #E2E8F0;
    border-radius: 0.75rem;
    margin-top: 2rem;
}

.trigger_card__heading {
    justify-content: space-between;
    align-items: center;
}

.trigger_card__heading h3{
    font-size: 1.25rem;
}

.time_on_page input[type="number"] {
    min-width: 75px;
    max-width: 170px;
}

.options_buttons_area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 700px) {
    .options_buttons_area {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 460px) {
    .options_buttons_area {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.options_button {
    background: var(--color1);
    border: 1px solid #E2E8F0 !important;
    color: var(--color6);
    box-shadow: none;
    line-height: 42px;
    font-weight: 600;
    width: auto;
    height: 35px;
    line-height: 34px;
    padding: 0 .5rem!important;
    font-size: 11px;
    border-radius: 12px;
    margin: 5px 2px 0px;
    line-height: 1.3em;
}

#w25{
 width: 24%;
}

#w50{
    width: 48%;
}

#w75{
    width: 75%;
}

#w74{
    width: 74%;
}

#w33{
    width: 32%;
}

#w67{
    width: 66%;
}

#w100 {
    width: 100%;
}

.select_multi .MuiInputBase-root{
  width: 100%;
  height: 3rem;
  border-radius: 12px;
}

.select_multi > div > fieldset {
  border: 1px solid #E2E8F0;
}

.add_filter_container{
  text-align: center;
  padding-top: 1.5rem;
}

.field_label {
  color: #718096;
  font-weight: 600;
}

.ruleGroup {
  border: 0px solid;
  background: #fff;
}

.ruleGroup-addRule,
.ruleGroup-addGroup,
.rule-remove,
.ruleGroup-remove {
    font-family: 'Rubik', 'Open Sans', sans-serif;
    letter-spacing: 0.2px;
    color: #194BFB;
    border: 1px solid #eee;
    box-shadow: none;
    font-weight: 600;
    width: auto;
    height: 40px;
    line-height: 34px;
    padding: 0 1rem;
    font-size: 14px;
    border-radius: 12px;
}

.ruleGroup-addRule:hover,
.ruleGroup-addGroup:hover,
.rule-remove:hover,
.ruleGroup-remove:hover {
  background: #194BFB;
  color: #fff;
  transition: .25s;
}

select.ruleGroup-combinators,
select.rule-fields,
select.rule-operators {
  height: 2.5rem;
  border-radius: 12px;
  padding: 8px 7px;
}

input.rule-value {
  height: 2.5rem;
  border-radius: 12px;
  padding: 8px 7px;
}

div.ruleGroup {
  padding: 5px 5px 5px 8px;
  border-left: 2px solid rgba(25, 75, 251, .5);
  border-radius: 0px;
}

.form_details {
  padding: 2rem 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.form_title h1 {
  font-size: 1.5em;
  margin: 0 auto 1em;
}

.flexBox {
  padding: 2rem 2rem 1.5rem;
  margin-top: 2rem;
}

.field_label h3 {
  margin: 1em auto;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 135%;
  display: flex;
  align-items: center;
  letter-spacing: .2px;
  color: #1a202c;
}

select {
  min-width: 100px;
  height: 2.5rem;
  border-radius: 12px;
  padding: 8px 7px;
}

.form_details--button {
  background-color: var(--color6);
  color: #fff;
  font-size: 18px;
  height: 40px;
  line-height: 34px;
  font-weight: 600;
  padding: 0 36px;
  border: none;
  border-radius: 12px;
}

.MuiInputBase-root {
  width: 100%;
}

.MuiSelect-select {
  width: 100%;
}

