.form_details {
  padding: 2rem 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.form_title h1 {
  font-size: 1.5em;
  margin: 0 auto 1em;
}

.flexBox {
  padding: 2rem 2rem 1.5rem;
  margin-top: 2rem;
}

.field_label h3 {
  margin: 1em auto;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 135%;
  display: flex;
  align-items: center;
  letter-spacing: .2px;
  color: #1a202c;
}

select {
  min-width: 100px;
  height: 2.5rem;
  border-radius: 12px;
  padding: 8px 7px;
}

.form_details--button {
  background-color: var(--color6);
  color: #fff;
  font-size: 18px;
  height: 40px;
  line-height: 34px;
  font-weight: 600;
  padding: 0 36px;
  border: none;
  border-radius: 12px;
}

.MuiInputBase-root {
  width: 100%;
}

.MuiSelect-select {
  width: 100%;
}
