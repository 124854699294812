#customize-dialog-box > div > div {
    max-width: 750px;
    padding: 5px;
}

.customized-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 455px){
    .customized-dialog-header > div  {
        display: flex;
        flex-direction: column;
    }
}

.customized-dialog-header > div > button {
    border: 2px solid red;
    margin: 5px;
    height: 40px;
    color: red;
    font-size: 14px;
    font-weight: 600;
    line-height: 34px;
}

.customized-dialog-header > div > button#test-btn {
    background: var(--color1);
    border: 1px solid #E2E8F0 !important;
    color: var(--color6);
    box-shadow: none;
    line-height: 42px;
    font-weight: 600;
    width: auto;
    height: 40px;
    line-height: 34px;
    padding: 0 1rem!important;
    font-size: 14px;
    border-radius: 12px;
}

.delete-button {
    border: 2px solid red;
    margin: 5px;
    height: 40px;
    color: red;
    font-size: 14px;
    font-weight: 600;
    line-height: 34px;
}

.customized-dialog-header > button:hover {
    border: 2px solid red;
    color: #fff;
    background-color: red;
}

.customized-dialog-box {
    padding: 16px;
    border-radius: 4px;
}

.customized-dialog-box div[role="dialog"] {
    height: 500px;
}

.customized-dialog-box__code-section {
    overflow-x: scroll;
    overflow-wrap: break-word;
    padding: 8px;
    border: 1px solid #E2E8F0;
    border-radius: 4px;
    margin: 12px;
    box-shadow: none;
}

.customized-dialog-box__code-section::-webkit-scrollbar-track,
.customized-dialog-box__code-section::-webkit-scrollbar {
    background: #fff;
}

.customized-dialog-box__button {
    text-align: center;
    padding: 16px 0 0;
    margin-bottom: 0;
}

.customized-dialog-box button {
    width: 150px;
    background: var(--color6);
    border: 0 none;
    color: var(--color1);
    box-shadow: none;
    line-height: 42px;
    font-weight: 600;
    height: 40px;
    line-height: 34px;
    padding: 0 1rem!important;
    margin-bottom: 16px;
    font-size: 14px;
    border-radius: 12px;
}

.trigger_section_layout {
    padding: 2rem 2.5rem 1rem;
    height: calc(100vh - 9rem);
    overflow-y: auto;
}
.trigger_section {
    /* height: 100%; */
    justify-content: space-between;
    align-items: center;
}

.trigger_section input{
    width: 16rem;
}

.trigger_card {
    padding: 2rem 2rem 1.5rem;
    border: 1px solid #E2E8F0;
    border-radius: 0.75rem;
    margin-top: 2rem;
}

.trigger_card__heading {
    justify-content: space-between;
    align-items: center;
}

.trigger_card__heading h3{
    font-size: 1.25rem;
}

.time_on_page input[type="number"] {
    min-width: 75px;
    max-width: 170px;
}

.options_buttons_area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 700px) {
    .options_buttons_area {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 460px) {
    .options_buttons_area {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.options_button {
    background: var(--color1);
    border: 1px solid #E2E8F0 !important;
    color: var(--color6);
    box-shadow: none;
    line-height: 42px;
    font-weight: 600;
    width: auto;
    height: 35px;
    line-height: 34px;
    padding: 0 .5rem!important;
    font-size: 11px;
    border-radius: 12px;
    margin: 5px 2px 0px;
    line-height: 1.3em;
}

#w25{
 width: 24%;
}

#w50{
    width: 48%;
}

#w75{
    width: 75%;
}

#w74{
    width: 74%;
}

#w33{
    width: 32%;
}

#w67{
    width: 66%;
}

#w100 {
    width: 100%;
}

.select_multi .MuiInputBase-root{
  width: 100%;
  height: 3rem;
  border-radius: 12px;
}

.select_multi > div > fieldset {
  border: 1px solid #E2E8F0;
}

.add_filter_container{
  text-align: center;
  padding-top: 1.5rem;
}
