.field_label {
  color: #718096;
  font-weight: 600;
}

.ruleGroup {
  border: 0px solid;
  background: #fff;
}

.ruleGroup-addRule,
.ruleGroup-addGroup,
.rule-remove,
.ruleGroup-remove {
    font-family: 'Rubik', 'Open Sans', sans-serif;
    letter-spacing: 0.2px;
    color: #194BFB;
    border: 1px solid #eee;
    box-shadow: none;
    font-weight: 600;
    width: auto;
    height: 40px;
    line-height: 34px;
    padding: 0 1rem;
    font-size: 14px;
    border-radius: 12px;
}

.ruleGroup-addRule:hover,
.ruleGroup-addGroup:hover,
.rule-remove:hover,
.ruleGroup-remove:hover {
  background: #194BFB;
  color: #fff;
  transition: .25s;
}

select.ruleGroup-combinators,
select.rule-fields,
select.rule-operators {
  height: 2.5rem;
  border-radius: 12px;
  padding: 8px 7px;
}

input.rule-value {
  height: 2.5rem;
  border-radius: 12px;
  padding: 8px 7px;
}

div.ruleGroup {
  padding: 5px 5px 5px 8px;
  border-left: 2px solid rgba(25, 75, 251, .5);
  border-radius: 0px;
}
