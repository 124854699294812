#file-upload-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 30px 45px 45px;
  border-radius: 15px;
}

#close-icon {
  width: 100%;
  text-align: right;
}

#file-upload-popup > h2 {
  margin: 0 !important;
}

#file-upload-popup > p {
  margin-bottom: 10%;
}

#file-upload-popup > button#close-icon {
  padding: 0px !important;
  margin-bottom: 5px !important;
}

.form-input input[type="text"] {
  margin: 5px 0 10px;
}

button.btn--submit {
  width: 100%;
  background-color: #194BFB;
  color: #fff;
  font-family: 'Rubik', 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 34px;
  padding: 0 1rem;
  border: 1px solid #194BFB;
  margin-top: 10px;
}

.success, .error {
  color: #fff;
  padding: .25em 1em;
  font-family: 'Rubik', 'Open Sans', sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 34px;
}

.error {
  background-color: red;
}

.success {
  background-color: green;
}
